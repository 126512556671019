<template>
    <div>
        <div class="banner">
            <img src="https://resources.holowits.com/terms/banner1.png" alt=""/>
        </div>
        <div class="list">
            <div class="item">
                <div class="t-label">I.Introduction</div>
                <div class="t-content">
                    <p>HOLOWITS hardware products are generally covered by a one (1) to three (3) year warranty. Most of products have warranty up to three (3) years. For detail information about corresponding product, please read the "HOLOWITS Product Warranty Claim" document.</p>
                    <p>All HOLOWITS warranties extend only to the HOLOWITS National Distributor (referred as ND, the enterprise who was authorized by HOLOWITS to sell products in certain country) in the countries or regions where you purchase the products; If the customers (the individual or entity who purchased the product for their own use, collectively referred to in this document as "Customer") have warranty issue, the service shall be provided by HOLOWITS ND or ND’s authorized Channel Partner (collectively referred to in this document as "Certified Service partner"). HOLOWITS warranties are subject to and provided only on the terms and conditions depict in this document. If there is any conflict with contract terms and conditions, the contract terms and conditions shall prevail.</p>
                </div>
            </div>
            <div class="item">
                <div class="t-label">II.General terms and conditions</div>
                <div class="t-content">
                    <p><strong>START DATE:</strong> The warranty normally starts on the 90th day after the date of the product shipment from HOLOWITS, or the date on which HOLOWITS receives a service request for this product, whichever is earlier.</p>
                    <p><strong>HARDWARE:</strong> HOLOWITS warrants that the HOLOWITS hardware products that you have purchased from HOLOWITS shall be free from defects in materials and workmanship under normal use consistent with the product instructions during the Warranty Period. In the event that HOLOWITS receives notice during the warranty period that any Hardware does not conform to its specifications, HOLOWITS shall, at its sole discretion, repair or replace the non-conforming hardware in accordance with this warranty. HOLOWITS replacement parts used in Hardware replacement may be new or equivalent to new in performance and reliability. If HOLOWITS cannot identify the reason of hardware products’ fault, National Distributor assumes the responsibility for on-site support.</p>
                    <p><strong>SOFTWARE:</strong> HOLOWITS warrants that for a period of ninety (90) days from the Start Date, the media, on which the software (“Software”) is recorded, shall be free from defects in material and workmanship under normal use consistent with the product instructions. The sole and exclusive remedy of the customer and the entire liability of HOLOWITS under this limited warranty shall be the replacement of the media containing the Software.</p>
                    <p><strong>REPLACEMENT/ REPAIR FOR HARDWARE:</strong> HOLOWITS will use commercially reasonable efforts to ship a repaired or replacement part after HOLOWITS identify the defective parts. And there are two ways to handle with the defective parts: 
                        1.some defective parts can be scrapped locally after an RMA is issued(refer to “HOLOWITS Product warranty claim”). 
                        2.some defective parts should be returned to HOLOWITS (refer to “HOLOWITS Product warranty claim”). 
                        For the replacement, some products can only ship to National distributor with next PO. But if the Advance Hardware Replacement is applicable, HOLOWITS will use commercially reasonable efforts to ship a replacement part within ten (10) business days after an RMA is issued. Actual delivery times may vary depending on National distributor location (refer to “HOLOWITS Product warranty claim”). If HOLOWITS does not received the defective parts within 30 calendar days, HOLOWITS reserves the right to charge the ND current price of the spare parts provided and adjust the policy of advanced hardware replacement. </p>
                    <ul>
                        <li>
                            <p><strong style="color: #000;">A.</strong>Please return the defective parts to a designated HOLOWITS site within 15 Business Days (BDs) upon receipt of the replacement parts. Please remove any confidential, proprietary, or personal information that is stored on the defective unit before it is returned to HOLOWITS. If customer cannot return the defective unit to HOLOWITS or need to degauss it due to data security, privacy, or other reasons, please purchase the Defective Parts Retention Service for retaining the faulty unit. In the event the equipment is not returned within this period, HOLOWITS reserves the right to charge you the purchase price of the product/parts provided.</p>
                        </li>
                        <li>
                            <p><strong style="color: #000;">B.</strong>Each party is responsible for the freight (return or shipping cost) respectively. If HOLOWITS determines that the faulty item does not meet the conditions of the warranty specified in this document, National distributor can either pay for the repair service or return the defective equipment to you at your own cost. National distributor should pay for the additional shipment fee if the spare parts shipped with next PO.</p>
                        </li>
                        <li>
                            <p><strong style="color: #000;">C.</strong>HOLOWITS warrants any expansion, repaired or replaced products/parts for ninety (90) days from shipment, or the remaining of the initial warranty period, whichever is longer.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="item">
                <div class="t-label">III.Limited Lifetime Warranty</div>
                <div class="t-content">
                    <p><strong>HARDWARE LIMITED LIFETIME WARRANTY DURATION:</strong>
                        As long as the original customer continues to own or use the product in the product life cycle. HOLOWITS limited lifetime warranty support will be terminated from the End of Service (EOS) date of the product.
                    </p>
                    <p><strong>ADVANCE HARDWARE REPLACE:</strong>
                        HOLOWITS or its authorized service center will use commercially reasonable efforts to ship a replacement part within ten (10) business days after receipt of the RMA request. Actual delivery times may vary depending on National Distributor location.
                    </p>
                </div>
            </div>
            <img style="margin-top: 20px; width: 100%;" src="@/assets/image/terms/tabel.png" alt="">
            <div style="margin: 20px 0px;">
                <div><strong>For details:</strong></div>
                <a href="https://resources.holowits.com/material/fae7d73fdb3f4707aa51c615363edb1a.xlsx" target="_blank">https://resources.holowits.com/material/fae7d73fdb3f4707aa51c615363edb1a.xlsx</a>
            </div>
            <div class="item">
                <div class="t-label">IV.Exclusions</div>
                <div class="t-content">
                    <p>HOLOWITS does not warrant uninterrupted or error-free operation of a product(e.g., If the installer doesn’t follow the installing guide or the installer set up the waterproof connector in unproper way, which cause hardware damage, HOLOWITS do not provide products warranty). The warranty is voided by removal or alteration of identification labels on the product or its parts.</p>
                    <p>This warranty does not apply to any non- HOLOWITS products (for example, the third party operating system or software); or the equipment that is licensed for beta, testing, training, evaluation or demonstration purposes; or consumables (cables, earphones, whiteboard markers, extenders, and distributors, refrigerant, refrigerant oil, air filter, humidifier, floodlight, fuse, Surge protection module, fire gas, etc.), accessories, and some terminals ( Access terminals, wireless terminals, thin client terminals).</p>
                    <p>HOLOWITS shall not have any obligation to provide maintenance in the following circumstances: (a) Accidents caused by force majeure (e.g. fire, flood, earthquake, lightning strike, etc.) (b)Poor service delivery conditions caused by social problems (e.g. social unrest, war, strike, social disharmony, government regulation, etc.) and (c) Failure to deliver services due to interruption of energy supply (e.g. power supply, water supply, oil supply, etc.)</p>
                    <p>This warranty does not extend to any damages, malfunctions, or non-conformities caused by (a) Force majeure, such as fire, flood, earthquake, war, etc.; (b) Abnormal physical or electrical stress (power surges, power outages, etc.); abnormal environmental conditions, misuse, negligence, virus infection, or accident; (c) Failure to follow installation, operation, or maintenance instructions supplied by HOLOWITS, together with the product or available on the HOLOWITS website; (d) Software, parts, or supplies not supplied by HOLOWITS; (e) Combination, modification or service by anyone without the authorization of HOLOWITS or its authorized representative; (f) Normal wear due to product use including, but not limited to, product cosmetics and display scratches; (g) Any solid-state drive (SSD), M.2 and Intelligent Computing Media (SD cards, SataDom, TPM cards, USB) the usage of which has reached its write endurance limit (excluding the SSD in Dorado storage product).</p>
                    <p>Customer is solely responsible for backing up its programs, data and removable storage media to protect against loss or corruption. HOLOWITS warranty obligations do not include restoration or re-installation support.</p>
                </div>
            </div>
            <div class="item">
                <div class="t-label">V.Disclaimer of warranty</div>
                <div class="t-content">
                    <p>Except as expressly set forth above, HOLOWITS makes no representation or warranty of any kind, express, implied or statutory, including but not limited to warranties of merchant ability, fitness for a particular purpose, title or non-infringement, or warranties or obligations arising from a course of dealing, usage or trade practice. Further, HOLOWITS does not warrant that the software is error free or that buyer will be able to operate the software without problems or interruption.</p>
                </div>
            </div>
            <div class="item">
                <div class="t-label">VI.Limitation of liability</div>
                <div class="t-content">
                    <p>In no event will Holowits be liable for any direct loss of use, interruption of business, lost profits, or lost data, or indirect, special, incidental or consequential damages of any kind regardless of the form of action, whether in contract, tort (including negligence), strict liability or otherwise, even if Holowits has been advised of the possibility of such damage, and whether or not any remedy provided should fail of its essential purpose. The total cumulative liability to customer, from all causes of action and all theories of liability, will be limited to and will not exceed the purchase price of the product paid by customer.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Warrantypolicy',

    data() {
        return {
            text1: ``
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.banner {
    width: 100%;
    img {
        width: 100%;
    }
}
.list {
    width: 1080px;
    margin: 25px auto;
    .item {
        & + .item {
            margin-top: 25px;
        }
        .t-label {
            font-size: 22px;
            color: #c7000b;
            font-weight: 600;
        }
        .t-content {
            background-image: linear-gradient(#fff, #cdcdcd7a);
            margin-top: 3px;
            padding: 8px;
            border: 1px solid gray;
            border-radius: 10px;
            white-space: pre-line;
            p {
                & + p {
                    margin-top: 30px;
                }
                strong {
                    color: #c7000b;
                }
            }
            ul {
                margin-top: 30px;
                padding-left: 30px;
                li {
                    margin-bottom: 10px;
                    // list-style-type: upper-alpha;
                }
            }
        }
    }
}
</style>